const metas = {
    "home": {
        "localhost": {
            "title": "Массовая рассылка сообщений",
            "description": "Массовая рассылка",
            "keywords": "массовая рассылка",
        },
        "baksbo.ru": {
            "title": "Массовая рассылка сообщений заказать на сайте цена в онлайн-сервисе BAKSBO",
            "description": "Массовая рассылка писем на телефон заказать в интернет-сервисе BAKSBO. Купить рассылку сообщений клиентам в мессенджерах или соцсетях недорого вы можете на сайте нашей компании. ",
            "keywords": "массовая рассылка сообщений, заказать, сайт, цена, онлайн, сервис, телефон, интернет, соцсети, мессенджеры, недорого, компания, номер телефона, письмо, привлечение клиентов, бывшие, потерянные, потенциальные, купить",
        },
        "msk.baksbo.ru": {
            "title": "Массовая рассылка сообщений заказать в Москве цена в онлайн-сервисе BAKSBO",
            "description": "Массовая рассылка сообщений на телефон цена в Москве в интернет-сервисе BAKSBO. Заказать рассылку писем клиентам в мессенджерах или соцсетях вы можете на сайте нашей компании онлайн.",
            "keywords": "рассылка сообщений, заказать, москва, цена, онлайн, сервис, интернет, клиент, мессенджер, соцсеть, сайт, компания, стоимость, письмо, отправка сообщений, номер телефона, доставка, привлечение, потерянный, бывший клиент, сеть, сервис массовой рассылки сообщений, сервис рассылки сообщений в соцсетях, сервис рассылки сообщений в мессенджерах, массовая рассылка сообщений в соцсетях, рассылка сообщений по всему миру, baksbo ru.",
        },
        "spb.baksbo.ru": {
            "title": "Рассылка сообщений заказать в Санкт-Петербурге цена в онлайн-сервисе BAKSBO",
            "description": "Массовую рассылку сообщений на телефон купить в Санкт-Петербурге цены в онлайн-сервисе BAKSBO. Заказать рассылку писем по базе недорого в мессенджеры или социальные сети на сайте.",
            "keywords": "рассылка сообщений, массовая рассылка, рассылка писем, на телефон, мессенджеры, база клиентов, социальные сети, онлайн, сервис, автоматические рассылки, рекламные сообщения, электронные сообщения, создать, заказать, купить, цена, санкт-петербург, недорого",
        },
        "krd.baksbo.ru": {
            "title": "Рассылка сообщений заказать в Краснодаре цена в онлайн-сервисе BAKSBO",
            "description": "Массовая рассылка сообщений на телефон купить в Краснодаре цены в сервисе BAKSBO. Создать автоматическую электронную рассылку рекламы и писем клиентам по базе и в социальных сетях на сайте.",
            "keywords": "рассылка сообщений, массовая рассылка, рассылка писем, на телефон, база клиентов, социальные сети, онлайн, сервис, автоматические рассылки, рекламные сообщения, электронные сообщения, создать, заказать, купить, цена, краснодар, недорого",
        },
    },
    "instagram": {
        "localhost": {
            "title": "Массовая рассылка сообщений",
            "description": "Массовая рассылка",
            "keywords": "массовая рассылка",
        },
        "baksbo.ru": {
            "title": "Массовая рассылка сообщений в Инстаграм (Instagram) заказать в сервисе BAKSBO",
            "description": "Массовая рассылка писем в Директ (Direct) Инстаграм заказать в сервисе BAKSBO. Купить Instagram рассылку сообщений для подписчиков или клиентов, примеры постов и цены на сайте. ",
            "keywords": "массовая рассылка в Instagram, инстаграм, заказать, сервис, директ, direct, аккаунт, цена, автоматическая, подписчик, купить, сообщения, письма, клиент, инста, пост, много, по диалогам, своим подписчикам ",
        },
        "msk.baksbo.ru": {
            "title": "Массовая рассылка сообщений в Инстаграм (Instagram) заказать в Москве в сервисе BAKSBO",
            "description": "Массовая рассылка Инстаграм сообщений в директе заказать в Москве в онлайн-сервисе BAKSBO. Купить Direct рассылку в Instagram аккаунтах цена услуги недорого на сайте нашей компании.",
            "keywords": "рассылка в Instagram, инстаграм, заказать, москва, сервис, директ, direct, аккаунт, цена, автоматическая, свой подписчик, безопасная, много, пост, холодный, бан, открытый диалог, после подписки, купить",
        },
        "spb.baksbo.ru": {
            "title": "Рассылка сообщений в Инстаграм заказать в Санкт-Петербурге цена в онлайн-сервисе BAKSBO",
            "description": "Массовая рассылка сообщений в Инстаграм заказать в Санкт-Петербурге цены в сервисе BAKSBO. Автоматическую рассылку сообщений в директ в Instagram для подписчиков создать на сайте.",
            "keywords": "рассылка сообщений в инстаграм, массовая рассылка, автоматическая рассылка, рассылка сообщений в instagram, подписчики, директ, direct, создать, сделать, заказать, купить, цена, санкт-петербург, недорого",
        },
        "krd.baksbo.ru": {
            "title": "Рассылка сообщений в Инстаграм заказать в Краснодаре цена в онлайн-сервисе BAKSBO",
            "description": "Массовая Инстаграм рассылка сообщений заказать в Краснодаре цены на сервисе BAKSBO. Сделать автоматическую Instagram рассылку сообщений подписчикам в директ на сайте недорого.",
            "keywords": "рассылка сообщений в инстаграм, массовая рассылка, автоматическая рассылка, рассылка сообщений в instagram, подписчики, директ, direct, создать, сделать, заказать, купить, цена, краснодар, недорого, на сайте",
        },
    },
    "telegram": {
        "localhost": {
            "title": "Массовая рассылка Телеграм",
            "description": "Массовая Телеграм.",
            "keywords": "массовая рассылка",
        },
        "baksbo.ru": {
            "title": "Массовая рассылка Телеграм (Telegram) сообщений заказать в сервисе BAKSBO",
            "description": "Массовая Телеграм рассылка заказать в онлайн-сервисе BAKSBO. Купить Telegram рассылку сообщений в группы, чаты, каналы или личные сообщения, цена на сайте нашей компании.",
            "keywords": "массовая рассылка в телеграм, заказать, цена, сообщение, сервис, онлайн, цена, группа, чат, канал, участник, личка, личные сообщения, сайт, компания, тг, telegram, телега, контакты, по номерам, приглашение, общая рассылка, стоимость",
        },
        "msk.baksbo.ru": {
            "title": "Массовая рассылка Телеграмм (Telegramm) сообщений заказать в Москве цена в сервисе BAKSBO",
            "description": "Массовая Телеграмм рассылка в заказать в Москве в онлайн-сервисе BAKSBO. Цена на Telegram рассылку сообщений в группы, чаты, каналы или личные сообщения на сайте нашей компании.",
            "keywords": "рассылка в телеграмм, заказать, цена, сообщение, сервис, онлайн, цена, группа, чат, канал, личка, личные сообщения, сайт, компания, тг, telegramm, телега, контакты, по номерам, приглашение, общая рассылка, участник группы, Id, стоимость",
        },
        "spb.baksbo.ru": {
            "title": "Рассылка сообщений в Телеграм заказать в Санкт-Петербурге цена в онлайн-сервисе BAKSBO",
            "description": "Массовая Telegram рассылка сообщений заказать в Санкт-Петербурге цена в сервисе BAKSBO. Сделать автоматическую Телеграм рассылку в чаты, каналы и группы по базе контактов в личку недорого.",
            "keywords": "рассылка сообщений в телеграм, массовая рассылка, автоматическая рассылка, рассылка сообщений в telegram, чат, канал, группа, санкт-петербург, сделать, заказать, недорого, купить, база, личка, контакты",
        },
        "krd.baksbo.ru": {
            "title": "Рассылка сообщений в Телеграм купить в Краснодаре цена в онлайн-сервисе BAKSBO",
            "description": "Массовая Telegram рассылка сообщений заказать в Краснодаре цены на сервисе BAKSBO. Сделать автоматическую Телеграм рассылку в чаты, в группы, подписчикам канала и в личные сообщения по базе номеров.",
            "keywords": "рассылка сообщений в телеграм, массовая рассылка, автоматическая рассылка, рассылка сообщений в telegram, чат, канал, группа, краснодар, сделать, купить, база, личные сообщения, подписчики, номера",
        },
    },
    "whatsapp": {
        "localhost": {
            "title": "Ватсап (Whatsapp)",
            "description": "Массовая Whatsapp.",
            "keywords": "массовая рассылка",
        },
        "baksbo.ru": {
            "title": "Ватсап (Whatsapp) массовая рассылка сообщений заказать в онлайн-сервисе BAKSBO",
            "description": "Массовая Whatsapp рассылка сообщений клиентам цена в онлайн-сервисе BAKSBO. Заказать Whatsapp рассылку писем для бизнеса вы можете платно, услуга на сайте компании.",
            "keywords": "массовая рассылка в ватсап, whatsapp, сообщение, письмо, сервис, онлайн, купить, цена, сервис, сделать, бизнес, отправить, клиент, по группам, картинка, по номерам, чат, услуга, платный, ссылка, стоимость, без добавления в контакты",
        },
        "msk.baksbo.ru": {
            "title": "Whatsapp (Ватсап) массовая рассылка заказать в Москве цена сообщения в сервисе BAKSBO",
            "description": "Массовая Whatsapp рассылка клиентам цена в Москве в онлайн-сервисе BAKSBO. Заказать Whatsapp Business холодную рассылку сообщений платно на сайте нашей компании.",
            "keywords": "рассылка в whatsapp, ватсап, вотсап, заказать, москва, цена, сообщение, сервис, онлайн, холодная рассылка, вацап, сделать, бизнес вацап, отправить, клиент, рассылка по группам, картинка, рассылка по номерам, чат, услуга, платный, ссылка, стоимость",
        },
        "spb.baksbo.ru": {
            "title": "Рассылка Whatsapp сообщений заказать в Санкт-Петербурге цена в онлайн-сервисе BAKSBO",
            "description": "Массовая Whatsapp рассылка сообщений заказать в Санкт-Петербурге цены в сервисе BAKSBO. Сделать автоматическую Ватсап рассылку клиентам в группы по базе номеров телефонов онлайн на сайте.",
            "keywords": "рассылка сообщений в ватсап, массовая рассылка, автоматическая рассылка, рассылка сообщений в whatsapp, сделать, заказать, купить, недорого, группы, база, номера телефонов, онлайн, сайт, санкт-петербург",
        },
        "krd.baksbo.ru": {
            "title": null,
            "description": null,
            "keywords": null,
        },
    },
    "vk": {
        "localhost": {
            "title": "Массовая рассылка сообщений",
            "description": "Массовая рассылка",
            "keywords": "рассылка во",
        },
        "baksbo.ru": {
            "title": "Массовая рассылка сообщений во Вконтакте заказать в интернет-сервисе BAKSBO",
            "description": "Массовая рассылка во Вконтакте заказать в интернет-сервисе BAKSBO. Купить VK рассылку сообщений в группы, личные сообщения, подписчикам или друзьям, цена поста на сайте компании. ",
            "keywords": "рассылка во вконтакте, заказать, сообщение, интернет-сервис, купить, группа, личное сообщение, подписчики, друзья, цена, пост, сайт, компания, vk, ссылка, лс, участникам группы, в чаты, в личку, новым подписчикам, онлайн",
        },
        "msk.baksbo.ru": {
            "title": "Массовая рассылка в Вконтакте заказать в Москве цена сообщения в сервисе BAKSBO",
            "description": "Массовая рассылка в Вконтакте заказать в Москве в онлайн-сервисе BAKSBO. Купить VK рассылку личных сообщений в группы, подписчикам или друзьям, цена поста на сайте компании. ",
            "keywords": "рассылка во вконтакте, заказать, цена, сообщение, сервис, онлайн, людям, личное сообщение, vk, группа, подписчик, друзьям, пост, вк, сделать, от имени сообщества, пользователю, письмо, лс, ссылка, участник группы, комментарий",
        },
        "spb.baksbo.ru": {
            "title": "Рассылка сообщений в ВК заказать в Санкт-Петербурге цена на сервисе BAKSBO",
            "description": "Массовая рассылка сообщений в Вконтакте заказать в сервисе BAKSBO. Сделать автоматическую рассылку VK сообщений по группам, сообществам, подписчикам или друзьям в личные сообщения недорого.",
            "keywords": "рассылка сообщений в вконтакте, массовая рассылка, автоматическая рассылка, рассылка сообщений в вк, сделать, заказать, недорого, группы, сообщества, подписчики, друзья, личные сообщения, санкт-петербург",
        },
        "krd.baksbo.ru": {
            "title": null,
            "description": null,
            "keywords": null,
        },
    },
    "soft": {
        "localhost": {
            "title": "BAKSBO-SOFT -",
            "description": "Программа для",
            "keywords": "софт для",
        },
        "baksbo.ru": {
            "title": "BAKSBO-SOFT - программа для рассылки сообщений в Телеграм купить на сайте BAKSBO",
            "description": "Программа для рассылки массовых сообщений по чатам Телеграм BAKSBO-SOFT купить на сайте. Софт для спама, авторассылки, парсинга и инвайтинга аудитории в Telegramm от BAKSBO. ",
            "keywords": "софт для рассылки, по чатам, телеграм, программа, купить, сайт, софт для телеграмма, письмо, приложение, мессенджер, рассылка объявлений, массовая рассылка, отправка сообщений, сервис, авторассылка, парсинг, инвайтинг",
        },
        "msk.baksbo.ru": {
            "title": "BAKSBO-SOFT - программа для рассылки сообщений в Телеграмм купить в Москве на сайте",
            "description": "Сервис для рассылки массовых сообщений в Телеграмм BAKSBO-SOFT купить в Москве на сайте онлайн. Софт для автоматической рассылки, парсинга и инвайтинга аудитории в Telegramm от BAKSBO.",
            "keywords": "программа для рассылки сообщений, телеграмм, telegramm, купить, москва, софт, сервис, цена, массовая рассылка, автоматическая рассылка, спам рассылка, письмо, на номер, чат, личные сообщения, канал, отправка сообщений, авторассылка",
        },
        "spb.baksbo.ru": {
            "title": "BAKSBO-SOFT - программа для рассылки сообщений купить в Санкт-Петербурге цена на сайте",
            "description": "Сервис для массовой рассылки сообщений в Телеграм купить в Санкт-Петербурге цена на BAKSBO. Софт для автоматической рассылки писем в Telegram купить приложение на сайте недорого.",
            "keywords": "программа для рассылки сообщений, софт для рассылки, телеграм, купить, санкт-петербург, сайт, софт для телеграмма, цена, письмо, приложение, массовая рассылка, отправка сообщений, сервис, авторассылка, парсинг, инвайтинг",
        },
        "krd.baksbo.ru": {
            "title": null,
            "description": null,
            "keywords": null,
        },
    },
    "bot": {
        "localhost": {
            "title": "BAKSBO-BOT -",
            "description": "Голосовой бот",
            "keywords": "голосовой бот",
        },
        "baksbo.ru": {
            "title": "BAKSBO-BOT - голосовой бот для звонков заказать под ключ от компании BAKSBO",
            "description": "Голосовой бот для звонков заказать разработку под ключ цена в компании BAKSBO. Купить бот для обзвона клиентов, продаж или спама звонков на телефон, услуга создания в нашем сервисе",
            "keywords": "голосовой бот для звонков, спам бот, искусственный инетеллект, нейросеть, бот обзвонщик, купить, заказать, цена, разработка, под ключ, продажи, создание, сервис, компания, для обзвона, клиент, регистрация на мероприятие, создание, прозвон",
        },
        "msk.baksbo.ru": {
            "title": "BAKSBO-BOT - голосовой бот для звонков заказать в Москве цена на сайте",
            "description": "Голосовой бот для звонков заказать в Москве цена разработки под ключ в сервисе BAKSBO. Бот для обзвона, продаж или спама звонков на телефон, услуга создания на нашем сайте.",
            "keywords": "бот для звонков, голосовой бот, заказать, на заказ, москва, цена, разработка, создание, сервис, обзвонщик, для бизнеса, спам звонки, услуга, звонок на телефон, стоимость, воронка продаж, спамер, прозвонщик, звонилка, под ключ",
        },
        "spb.baksbo.ru": {
            "title": "BAKSBO-BOT - голосовой робот для звонков заказать в Санкт-Петербурге цена на сайте",
            "description": "Голосовой бот для массового обзвона купить в Санкт-Петербурге цена в сервисе BAKSBO. Создать бот для продаж, входящих, исходящих и спам звонков клиентам услуга на сайте недорого.",
            "keywords": "голосовой робот для звонков, бот для массового обзвона, бот обзвонщик, санкт-петербург, купить, заказать, цена, продажи, создать, сервис, для обзвона, входящие, исходящие, спам, клиент, сайт, недорого",
        },
        "krd.baksbo.ru": {
            "title": null,
            "description": null,
            "keywords": null,
        },
    },
}
export default metas