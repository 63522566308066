import axios from "axios"
import { useRef, useState } from "react"
import { useEffect } from "react"
import Bounus from "../../elements/bonus/bonus"
import ChatFon from "../../elements/chatFon/ChatFon"
import MailingForm from "../../elements/mailingForm/mailingForm"
import "./social.css"
import Aos from 'aos'
import 'aos/dist/aos.css'
import MetaTags from 'react-meta-tags';
import Case from "../../elements/case/case"
import Benefice from "../../elements/benefice/benefice"
import StatisticCase from "../../elements/statisticKase/statisticCase"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import BotCommercial from "../../elements/botCommercial/botCommercial"
import SelfMailing from "../../elements/selfMailing/selfMailing"
import { Helmet } from "react-helmet"
import headers from "../../seo/headers"



export default function Viber() {
    const toMailing = useRef('')
    const finger = useRef('')
    const tarifFon = useRef('')
    const intervalRef = useRef('')
    const [scrollpPos, setScrollpPos] = useState(2)
    const [tarifFonScroll, setTarifFonScroll] = useState(null)

    useEffect(() => {
        Aos.init({ duration: 1500 })
        setTarifFonScroll(true)
    }, [])

    useEffect(() => {
        if (tarifFonScroll === true) {
            if (document.body.offsetWidth < 950) {
                intervalRef.current = setInterval(function () {
                    tarifFon.current.scrollTo(0, 0)
                    setTimeout(() => {
                        tarifFon.current.scrollTo(tarifFon.current.scrollWidth, 0)
                    }, 2000);
                }, 5000);
            }
        }
    }, [tarifFonScroll])

    function canselScroll() {
        clearInterval(intervalRef.current)
        finger.current.style.display = 'none'
    }

    let selfDivWidth = 950
    if (document.body.offsetWidth < 1100) {
        selfDivWidth = 810
    }
    if (document.body.offsetWidth < 900) {
        selfDivWidth = 710
    }
    if (document.body.offsetWidth < 700) {
        selfDivWidth = 360
    }
    let width = (document.body.offsetWidth - selfDivWidth)


    let positions = []
    let count = 0
    for (let i = 0; i < 5; i++) {
        positions.push(count)
        count += selfDivWidth

    }

    const [price, setPrice] = useState('')
    const [base, setBase] = useState('')
    const [tarif1, setTarif1] = useState('')
    const [tarif1_sale, setTarif1_sale] = useState('')
    const [tarif1_base, setTarif1_base] = useState('')
    const [tarif1_price, setTarif1_price] = useState('')
    const [tarif1_all, setTarif1_all] = useState('')
    const [tarif2, setTarif2] = useState('')
    const [tarif2_sale, setTarif2_sale] = useState('')
    const [tarif2_base, setTarif2_base] = useState('')
    const [tarif2_price, setTarif2_price] = useState('')
    const [tarif2_all, setTarif2_all] = useState('')
    const [tarif3, setTarif3] = useState('')
    const [tarif3_sale, setTarif3_sale] = useState('')
    const [tarif3_base, setTarif3_base] = useState('')
    const [tarif3_price, setTarif3_price] = useState('')
    const [tarif3_all, setTarif3_all] = useState('')
    const [tarif4, setTarif4] = useState('')
    const [tarif4_sale, setTarif4_sale] = useState('')
    const [tarif4_base, setTarif4_base] = useState('')
    const [tarif4_price, setTarif4_price] = useState('')
    const [tarif4_all, setTarif4_all] = useState('')
    const [tarif5, setTarif5] = useState('')
    const [tarif5_sale, setTarif5_sale] = useState('')
    const [tarif5_base, setTarif5_base] = useState('')
    const [tarif5_price, setTarif5_price] = useState('')
    const [tarif5_all, setTarif5_all] = useState('')
    const [tarif6, setTarif6] = useState('')
    const [tarif6_sale, setTarif6_sale] = useState('')
    const [tarif6_base, setTarif6_base] = useState('')
    const [tarif6_price, setTarif6_price] = useState('')
    const [tarif6_all, setTarif6_all] = useState('')

    useEffect(() => {
        axios.get('/api/viber')
            .then(request => request.data.social)
            .then(request => {
                setPrice(request.price)
                setBase(request.base)
                setTarif1(request.tarif1)
                setTarif1_sale(request.tarif1_sale)
                setTarif1_base(request.tarif1_base)
                setTarif1_price(request.tarif1_price)
                setTarif1_all(request.tarif1_all)
                setTarif2(request.tarif2)
                setTarif2_sale(request.tarif2_sale)
                setTarif2_base(request.tarif2_base)
                setTarif2_price(request.tarif2_price)
                setTarif2_all(request.tarif2_all)
                setTarif3(request.tarif3)
                setTarif3_sale(request.tarif3_sale)
                setTarif3_base(request.tarif3_base)
                setTarif3_price(request.tarif3_price)
                setTarif3_all(request.tarif3_all)
                setTarif4(request.tarif4)
                setTarif4_sale(request.tarif4_sale)
                setTarif4_base(request.tarif4_base)
                setTarif4_price(request.tarif4_price)
                setTarif4_all(request.tarif4_all)
                setTarif5(request.tarif5)
                setTarif5_sale(request.tarif5_sale)
                setTarif5_base(request.tarif5_base)
                setTarif5_price(request.tarif5_price)
                setTarif5_all(request.tarif5_all)
                setTarif6(request.tarif6)
                setTarif6_sale(request.tarif6_sale)
                setTarif6_base(request.tarif6_base)
                setTarif6_price(request.tarif6_price)
                setTarif6_all(request.tarif6_all)
            }
            )
        window.scroll(0, 0)
    }, [])

    return (
        <>

            <Helmet>
                <title>Сервис массовой рассылки сообщений в Viber - Baksbo.ru</title>
                <meta name="description" content="Если вам нужна массовая рассылка сообщений в Вайбер с уведомлением, тогда сервис Baksbo.ru к вашим услугам! Ознакомиться с ценами на рассылку можно на сайте."></meta>
                <meta name="keywords" content="рассылка сообщений в вайбер цены, viber рассылка сообщений, массовая рассылка сообщений в viber, массовая рассылка сообщений в вайбере, рассылка сообщений в вайбере, сервис рассылки сообщений viber."></meta>
                <meta property="og:image:secure" content="/static/img/icons/viber.png"></meta>
            </Helmet>
            <ChatFon />
            <div className='homeHeader'>
                <div className="eclipse viberEclipse"></div>
                <div className="homeHeaderText">
                    <h1 className='hiddetH1Seo'>Рассылка сообщений в Viber {headers[window.location.hostname]}</h1>
                    <h4>Рассылка сообщений</h4>
                    <h2>в Viber от {price}<b>₽</b></h2>
                    <p>Продавайте свой продукт целевой аудитории гораздо быстрее и качественней, <i>каждое сообщение доходит до клиента с уведомлением.</i></p>
                    <div className='homeHeaderButtonDiv'>
                        <a href="#toTarif">
                            <button className='getPresent'>Tарифы</button>
                        </a>
                        <Link to="/#freeConsultation">
                            <button className='getPresent'>Консультация</button>
                        </Link>
                        <div className="headerSocialLinks">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                                <img src="static/img/icons/insta.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                                <img src="static/img/icons/tg.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                                <img src="static/img/icons/waapp.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                                <img src="static/img/icons/vk.png" alt="" />

                            </a>
                        </div>
                    </div>
                    <div className='rates'>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.8 Яндекс</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.9 Google</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Авито</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Wdomain</span>
                        </div>
                    </div>
                </div>
                <div className="homeHeaderImage">
                    <img src="static/img/social/viber/hand.png" alt="" />
                </div>
            </div>

            <p className='headerSocialText'>Мы тут есть</p>
            <div id='headerSocialLinks' className="headerSocialLinks">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                    <img src="static/img/icons/insta.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                    <img src="static/img/icons/tg.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                    <img src="static/img/icons/waapp.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                    <img src="static/img/icons/vk.png" alt="" />

                </a>
            </div>
            <StatisticCase />
            <div className="socailScreens socailScreensViber">
                <h2>Варианты сообщений</h2>
                <div className="socailScreensSection">
                    <div className="screenshot screenshotViber">
                        <p>Видео + текст + ссылки </p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №1</span>
                        {/* <img src="static/img/social/whatsapp/screen1.png" alt="" /> */}
                    </div>
                    <div className="screenshot screenshotViber">
                        <p>Картинку + текст + ссылки</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №2</span>
                        {/* <img src="static/img/social/whatsapp/screen2.png" alt="" /> */}
                    </div>
                    <div className="screenshot screenshotViber">
                        <p>Текст + ссылки</p>
                        <div className="workLine socialLine"></div>
                        <span>вариант №3</span>
                        {/* <img src="static/img/social/whatsapp/screen4.png" alt="" /> */}
                    </div>
                </div>
                <span>Если у вас возникли вопросы по рассылке информации, то вы можете задать их нашему менеджеру в мессенджер или по телефону.</span>
                <p>*отправляется только один вариант</p>
            </div>
            <Case />
            <Benefice />
            <div className="mailingDiv">
                <div className='workSection socialWorkSection'>
                    <div data-aos="fade-up" className="workImage"><img src="static/img/social/viber/viber.png" alt="" /></div>
                    <div className="workText">
                        <h5>Раскачай свой бизнес</h5>
                        <div className='workLine'></div>
                        <p><i>НАЧНИТЕ ЭКОНОМИТЬ СВОИ ДЕНЬГИ НА РЕКЛАМЕ</i></p>
                        <span>Находите быстрее своих клиентов с помощью массовой рассылки в Viber. <i>Клиенты получают сообщение в свой мессенджер с уведомлением,</i> тем самым не могут его пропустить и обязательно прочитают, так как сообщение будет отображаться в непрочитанных.</span>
                    </div>
                </div>
                <div id="toTarif" className="tarifDiv">
                    <h2>тарифы</h2>
                    <p>ОПЛАТА ПРОИЗВОДИТСЯ СТРОГО ПОСЛЕ ПОДТВЕРЖДЕНИЯ ЗАКАЗА МЕНЕДЖЕРОМ</p>
                    <div onTouchStart={canselScroll} onTouchMove={canselScroll} ref={tarifFon} className="tarifFon">
                        <div onTouchStart={canselScroll} onTouchMove={canselScroll} className="tarifs">
                            <div className="tarifInfo">
                                <p>Количество сообщений</p>
                                <span>от {tarif1} шт.</span>
                                <span>от {tarif2} шт.</span>
                                <span>от {tarif3} шт.</span>
                                <span>от {tarif4} шт.</span>
                                <span>от {tarif5} шт.</span>
                                <span>от {tarif6} шт.</span>
                            </div>
                            <div className="tarifLine"></div>
                            <div className="tarifInfo">
                                <p>Стоимость за сообщение</p>
                                <span>{tarif1_price} ₽</span>
                                <span>{tarif2_price} ₽</span>
                                <span>{tarif3_price} ₽</span>
                                <span>{tarif4_price} ₽</span>
                                <span>{tarif5_price} ₽</span>
                                <span>{tarif6_price} ₽</span>
                            </div>
                            <div className="tarifLine"></div>
                            {base !== 'none' ?
                                <>
                                    <div className="tarifInfo">
                                        <p>Стоимость базы <br />(наш сбор {base} ₽)</p>
                                        <span>{tarif1_base} ₽</span>
                                        <span>{tarif2_base} ₽</span>
                                        <span>{tarif3_base} ₽</span>
                                        <span>{tarif4_base} ₽</span>
                                        <span>{tarif5_base} ₽</span>
                                        <span>{tarif6_base} ₽</span>
                                    </div>
                                    <div className="tarifLine"></div>
                                </>
                                :
                                null
                            }

                            <div className="tarifInfo">
                                {base !== 'none' ?
                                    <p>Итоговая стоимость рассылки</p>
                                    :
                                    <p>Стоимость рассылки</p>
                                }
                                <span>{tarif1_all} ₽</span>
                                <span>{tarif2_all} ₽</span>
                                <span>{tarif3_all} ₽</span>
                                <span>{tarif4_all} ₽</span>
                                <span>{tarif5_all} ₽</span>
                                <span>{tarif6_all} ₽</span>
                            </div>
                            <div className="tarifLine"></div>
                            <div className="tarifInfo">
                                <p>Скидка</p>
                                <span>- {tarif1_sale} %</span>
                                <span>- {tarif2_sale} %</span>
                                <span>- {tarif3_sale} %</span>
                                <span>- {tarif4_sale} %</span>
                                <span>- {tarif5_sale} %</span>
                                <span>- {tarif6_sale} %</span>
                            </div>
                        </div>
                        <img ref={finger} className="finger" src="/static/img/finger.png" alt="" />
                        <a href="#mailingFomr">
                            <button>Заказать рассылку</button>
                        </a>
                    </div>
                </div>
                <div className="legs">
                    <Bounus />
                </div>

                <div className='workSection workTextOtchyot'>
                    <div className="workText">
                        <h5>ОТЧЁТНОСТЬ ДО/ПОСЛЕ РАБОТЫ</h5>
                        <div className='workLine'></div>
                        <p>НАМ НЕЧЕГО СКРЫВАТЬ ОТ ВАС</p>
                        <span>
                            Отчетность отправленных сообщений предоставляется в три этапа.
                            <br /><br />
                            <b>Первый</b> - это скрины экрана пк, где мы показываем, что рассылка запущена и производится в штатном режиме.
                            <br /><br />
                            <b>Второй</b> - это скрин экрана пк, где мы показываем, какое количество личных сообщений было успешно отправлено пользователям.
                            <br /><br />
                            <b>Третий</b> - это выгрузка в Excel файл полного отчета по рассылке. Где будет указан: телефон/доставлено
                        </span>
                    </div>
                    <div data-aos="fade-up" className="workImage"><img src="static/img/lists.png" alt="" /></div>
                </div>
                <div id="toMailing"></div>
                <MailingForm platform={'viber'} />

                <h2 className='faqHeader'>Часто задаваемые вопросы</h2>
                <div className='homeFAQ'>
                    <ul>
                        <li>С каких номеров идёт рассылка в Viber?</li>
                        <br />
                        <p>- Рассылка сообщений в Viber производится с наших номеров. Тем самым ваш номер находится в полной безопасности от блокировки. </p>
                    </ul>
                    <ul>
                        <li>От куда брать базу для рассылки сообщений в Viber?</li>
                        <br />
                        <p>- Мы поможем вам с этим. Наши аналитики подберут площадки для сбора базы, отталкиваясь от ваших пожеланий и ниши бизнеса. Сбор базы платный, стоимость сбора базы составляет 1 номер = 1 руб, если объём сбора большой, то стоимость обговаривается индивидуально и может быть снижена. Базу после сбора отдаём вам в формате excel, в дальнейшем вы сможете с ней работать по другим направлениям. Базу мы можем отфильтровать по множеству настроек: </p>
                        <p>&nbsp;&nbsp;• Пол </p>
                        <p>&nbsp;&nbsp;• Возраст </p>
                        <p>&nbsp;&nbsp;• Геолокация </p>
                        <p>&nbsp;&nbsp;• Интересы и так далее.  </p>
                        <p>Все настройки фильтра согласовываются с вами, тем самым мы сможем максимально отсеять ненужные номера.</p>
                    </ul>
                    <ul>
                        <li>Какие виды сообщений можно отправлять в Viber?</li>
                        <br />
                        <p>- Все виды сообщений, которые возможно отправить в Viber, предоставлены визуально и описаны выше на данной странице. Отправлять можно следующее:</p>

                        <p>&nbsp;&nbsp;• Текст + до 3х ссылок</p>
                        <p>&nbsp;&nbsp;• Картинка + текст + до 3х ссылок</p>
                        <p>&nbsp;&nbsp;• Видео + текст + до 3х ссылок</p>
                        <p>В тексте можно указать до 3-х ссылок на любые ресурсы. В качестве БОНУСА наш модератор поможет вам с написанием текста для рассылки, он структуризирует ваше сообщение и сделает его уникальным, тем самым максимально увеличит конверсию. Каждый текст редактируется индивидуально, без каких-либо шаблонов.</p>
                    </ul>
                    <ul>
                        <li>Какая отчетность у Viber рассылки?</li>
                        <br />
                        <p>- На протяжении всей работы наши менеджеры находятся на связи с вами и готовы ответить на любой вопрос. В виде отчётности мы предоставляем на старте запуска рассылки скрин экрана, что рассылка началась и запущена, в конце работы высылаем скрин окончания работы, где указано количество успешно отправленных сообщений, также на протяжении всей рассылки мы по вашему запросу можем выслать дополнительные скрины в виде отчета. После завершения рассылки мы ставим отчёт на выгрузку, в отчете вы увидите: </p>
                        <p>&nbsp;&nbsp;• Доставлено / не доставлено </p>
                        <p>В данном отчете вы сможете проанализировать свою рассылку. </p>

                    </ul>
                    <ul>
                        <li>Сколько стоит рассылка сообщений в Viber?</li>
                        <br />
                        <p>- При обращении уже с готовой базой номеров, по которой необходимо сделать массовую рассылку, мы ее проверим на валидность (нахождение программы Viber на смартфоне), это делается абсолютно бесплатно, в этом случае оплачивается только рассылка с расчетом вашего объёма. Если нет готовой базы, тогда к стоимости рассылки прибавляется стоимость сбора базы. Подробнее вы можете ознакомиться в блоке «Тарифы» выше на данной странице. Если у вас возникли вопросы о стоимости рассылки, то заполните форму «бесплатная консультация» и мы свяжемся с вами.</p>

                    </ul>
                    <ul>
                        <li>Что такое рассылка сообщений в Viber под ключ?</li>
                        <br />
                        <p>- Мы-команда профессионалов с большим опытом работы в сфере лидогенирации через рассылки в Viber четко понимаем, что необходимо для рассылки в той нише, где находится ваш бизнес. Поэтому мы решили сделать рассылку под ключ. Наша команда подготовит полностью все этапы для запуска рассылки в Viber. Вам остаётся только согласовывать те или иные этапы работы.</p>
                        <p>Этапы подготовки:</p>
                        <p>&nbsp;&nbsp;• Аналитика вашей ниши</p>
                        <p>&nbsp;&nbsp;• Поиск площадок для сбора базы</p>
                        <p>&nbsp;&nbsp;• Сбор целевой базы </p>
                        <p>&nbsp;&nbsp;• Фильтрация целевой базы </p>
                        <p>&nbsp;&nbsp;• Определение структуризации сообщений</p>
                        <p>&nbsp;&nbsp;• Помощь в написании текста для рассылки</p>
                        <p>&nbsp;&nbsp;• Запуск рассылки (с предоставлением отчетов)</p>
                        <p>&nbsp;&nbsp;• Поддержка клиентского отдела на протяжении всей работы</p>
                    </ul>

                </div>
            </div>

        </>
    )
}